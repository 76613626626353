
import { defineComponent, reactive, toRefs } from "vue";
import { localStorageKey } from "@/utils/ajax";

export default defineComponent({
  setup() {
    const dataMap = reactive({
      show: true,
      dataList: {} as any,
    });

    const clearUser = () => {
      window.localStorage.clear();
      dataMap.show = !dataMap.show;
    };
    localStorageKey.forEach((el) => {
      dataMap.dataList[el] = window.localStorage.getItem(el);
    });

    return {
      ...toRefs(dataMap),
      clearUser,
    };
  },
});
