<template>
  <div>
    <div class="bt_box">
      <button type="submit" v-show="show" @click="clearUser">点我清除登陆信息</button>
      <span v-show="!show">登陆信息已清除！</span>
    </div>
    <div class="info">
      <p v-for="(item, index) in dataList" :key="index">{{ index }}: {{ item }}</p>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, toRefs } from "vue";
import { localStorageKey } from "@/utils/ajax";

export default defineComponent({
  setup() {
    const dataMap = reactive({
      show: true,
      dataList: {} as any,
    });

    const clearUser = () => {
      window.localStorage.clear();
      dataMap.show = !dataMap.show;
    };
    localStorageKey.forEach((el) => {
      dataMap.dataList[el] = window.localStorage.getItem(el);
    });

    return {
      ...toRefs(dataMap),
      clearUser,
    };
  },
});
</script>

<style lang="less" scoped>
.bt_box {
  text-align: center;
}

button {
  height: 50px;
  line-height: 50px;
  margin: 20% auto;
}
.info{
  padding: 0 10px;
}
span {
  display: inline-block;
  height: 50px;
  line-height: 50px;
  margin: 60px auto 30px auto;
  padding: 0 20px;
  font-size: 20px;
  background-color: #eee;
  border-radius: 10px;
  border: 1px solid #eee;
}
</style>
